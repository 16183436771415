import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import WebFont from 'webfontloader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
console.log('%cMCMARTiN DAGADT', 'color: red; font-size: 200px;')