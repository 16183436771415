import React from 'react'
import Navbar from '../navbar/Navbar'
import Button from '../buttons/Button'
import './cards.scss'

const Cards = (props) => {
    
    if(props.type === "card1")
        return (
            <div>
                <Navbar />
                <div className='card1'>
                    <div className='card1__bg'>

                    </div>
                    <div className=' card1__container'>
                        <div className='card1__container__item'>
                            <div className='card1__container__item__tittle'>
                                <h1>Egy modern 2fa rendszer</h1>
                            </div>
                            <div className='card1__container__item__text'>
                                <p>Elvették a suliban a telefonod, emiatt korlátoz a 2fa?</p>
                                <p>Esetleg elfelejtetted a jelszavad?</p>
                            </div>
                            <div className='card1__container__item__footer'>
                                <h1>Elhoztuk a megoldást!</h1>
                                <div className='card1__container__item__footer__buttons'>
                                    <Button type="login"></Button>
                                    <Button type="reg"></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    if(props.type === "card2")
    return (
        <div>
            <div className='card2'>
                <div className='card2__container'>
                    <h1>Miért válassz minket?</h1>
                    <div className='card2__container__line'></div>
                </div>
            </div>
        </div>
        )
    if(props.type === "card3")
        return (
                <div>
                    <div className='card3'>
                        <div className='card3__container'>
                            <h1>Egyszerű kezelés</h1>
                            <div className='card3__container__decoration'></div>
                            <p>Kurva gyorsan megérthető a rendszer, csak el kell olvasni az 500 oldalas dokumentációt</p>
                        </div>
                    </div>
                </div>
        )
    if(props.type === "card4")
            return (
                    <div>
                        <div className='card4'>
                            <div className='card4__container'>
                                <div className='card4__container__tittle'>
                                    <h1>Akármilyen</h1>
                                    <h1>eszközről</h1>
                                    <h1>használhatod</h1>
                                </div>
                                <p>Elég csak egy profilt létrehoznod, majd akármilyen telefonról, pc ről, vagy bármi másról el tudod érni</p>
                            </div>
                        </div>
                    </div>
            )
    if(props.type === "card5")
                return (
                        <div>
                            <div className='card5'>
                                <div className='card5__container'>
                                    <h1>Egyszerűen  kezelheted jelszavaidat</h1>
                                    <div className='card5__container__decoration'></div>
                                    <p>A jelszavaidat biztonságosan, egyszerűen tudod tárolni itt, és mivel 2 jelszó kell ahoz hogy megtekintsed őket, így fokozott a biztonság is</p>
                                </div>
                            </div>
                        </div>
                )


    return (
        <button className={ props.hidden ? 'button button_hidden' : 'button' } onClick={ props.onClick }>{ props.text }</button>
    )
}

export default Cards