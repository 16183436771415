import React from 'react'
import Cards from '../components/cards/Cards'

const MainPage = () => {
  return (
    
    <div>
        <Cards type="card1"/>
        <Cards type="card2" />
        <Cards type="card3" />
        <Cards type="card4" />
        <Cards type="card5" />
    </div>
  )
}

export default MainPage