import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import './assets/scss/App.scss'
import MainPage from './pages/MainPage';


function App() {
	return (
		<BrowserRouter >
			<Routes>
				<Route path= '/'  element= { <MainPage ></MainPage> }/>	
			</Routes>
		</BrowserRouter>
	);
}

export default App;
