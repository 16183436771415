import React from 'react'
import Button from '../buttons/Button'
import './navbar.scss'

const Navbar = () => {
  return (
    <div>
        <div className='navbar'>
            <div className='navbar__container'>
                <div className='navbar__container__tittle'>
                    <h1>SHARK</h1>
                    <h2>VERIFY </h2>
                    <div className='navbar__container__tittle__line'></div>
                </div>
                <div className='navbar__container__list'>
                    <div className='navbar__container__list__item'>
                        <p>Miért mi?</p>
                    </div>
                    <div className='navbar__container__list__item'>
                        <p>Kezelés</p>
                    </div>
                    <div className='navbar__container__list__item'>
                        <p>Kapcsolatok</p>
                    </div>
                    <div className='navbar__container__list__item'>
                        <Button type="login"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Navbar