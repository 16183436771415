import React from 'react'
import './buttons.scss'

const Button = (props) => {
    if(props.type === "login")
        return (
            <button className={ props.hidden ? 'button button_hidden' : 'button button__login' } onClick={ props.onClick }>Bejelentkezés</button>
        )

    if(props.type === "reg")
    return (
        <button className={ props.hidden ? 'button button_hidden' : 'button button__reg' } onClick={ props.onClick }>Regisztráció</button>
    )


    return (
        <button className={ props.hidden ? 'button button_hidden' : 'button' } onClick={ props.onClick }>{ props.text }</button>
    )
}

export default Button